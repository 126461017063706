import common from './common'

export default {
  ...common.button,
  color: `grayDark`,
  bg: `mute`,
  borderColor: `mute`,
  ':hover': {
    color: `background`,
    bg: `primary`,
    borderColor: `primary`
  }
}
