import { useStaticQuery, graphql } from 'gatsby'

export const useSiteConfig = () => {
  const { site } = useStaticQuery(siteConfigQuery)
  return site.siteMetadata
}

const siteConfigQuery = graphql`
  query SiteQuery {
    site {
      ...SiteConfig
    }
  }
`
