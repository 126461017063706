import common from './common'

export default {
  ...common.button,
  color: `grayDark`,
  bg: `white`,
  borderColor: `gray`,
  ':hover': {
    borderColor: `primary`
  }
}
