import common from './common'

export default {
  ...common.button,
  color: `white`,
  bg: `grayDark`,
  borderColor: `grayDark`,
  ':hover': {
    bg: `primary`,
    color: `white`,
    borderColor: `primary`
  }
}
