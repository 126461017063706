export default {
  color: `grayDark`,
  textDecoration: `none`,
  userSelect: `none`,
  ':visited': {
    color: 'grayDark'
  },
  ':hover': {
    color: 'primary'
  }
}
