import common from './common'

export default {
  ...common.badge,
  bg: `grayLight`,
  color: `grayDark`,
  ':hover': {
    color: `grayLight`,
    bg: `grayDark`
  }
}
