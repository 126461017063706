import Loadable from '@loadable/component'

import { default as headings } from './Headings'
import { default as Figure } from './Figure'
import { default as Figcaption } from './Figcaption'
// import { default as CodeBlock } from './CodeBlock'

export default {
  figure: Figure,
  figcaption: Figcaption,
  pre: Loadable(() => import('./CodeBlock')),
  ...headings
}
