import colors from './color-preset'

const primary = colors.indigo
const secondary = colors.purple
const opaqueLightYellow = `rgba(255, 229, 100, 0.2)`

export default {
  text: colors.gray[600],
  article: colors.gray[700],
  heading: colors.gray[800],

  grayLighter: colors.gray[200],
  grayLight: colors.gray[300],
  gray: colors.gray[500],
  grayDark: colors.gray[600],
  grayDarker: colors.gray[800],

  successLight: colors.green[100],
  success: colors.green[600],
  errorLight: colors.red[100],
  error: colors.red[500],

  white: colors.white,
  background: `#f8f8f8`,
  contentBg: colors.white,
  headerBg: colors.white,
  footerBg: colors.white,

  primaryLight: primary[300],
  primary: primary[600],
  primaryDark: primary[800],

  secondaryLight: secondary[400],
  secondary: secondary[600],
  secondaryDark: secondary[800],

  mute: colors.gray[300],
  highlight: opaqueLightYellow,

  prism: {
    background: `#011627`,
    comment: `#809393`,
    string: `#addb67`,
    var: `#d6deeb`,
    number: `#f78c6c`,
    constant: `#82aaff`,
    punctuation: `#c792ea`,
    className: `#ffc98b`,
    tag: `#ffa7c4`,
    boolean: `#ff5874`,
    property: `#80cbc4`,
    namespace: `#b2ccd6`,
    highlight: `hsla(207, 95%, 15%, 1)`
  }

  // modes: {
  //   dark: {
  //     // text: lightWhite,
  //     // background: colors.gray[800],
  //     // primary: purple30,
  //     // secondary: lightWhite,
  //     // muted: opaqueLightWhite,
  //     // highlight: purple60,
  //     // heading: white
  //   },
  // },
}
